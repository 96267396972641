import { Component } from '@angular/core';
import { AuthProvidersService } from './providers/auth/auth-provider.service';
import { Location } from '@angular/common';
declare var UIkit: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'smoochie-child';
  loggedInUserData: any;
  showContent: any = null;
  currentPage: string;
  isCheckoutPage: boolean;

  constructor(
    private authData: AuthProvidersService,
    private _location: Location
  ) {
    this.authData.currentUser().subscribe((data) => {
      this.showContent = data;
      if(data) {
        this.loggedInUserData = data;
      }
    });
    this.currentPage = this._location.path();
    
  }

  ngOnInit() {
    
  }

  hideLoginCloseButton() {
    this.isCheckoutPage = this.currentPage.indexOf('checkout') > -1;
    // console.log(this.currentPage);
  }
  ngOnChanges() {
    this.hideLoginCloseButton()
  }

  onLoggingOut() {
    this.authData.logoutUser().then((data) => {
      UIkit.notification({ message: 'You have logged out successfully', pos: 'bottom-center', status: 'success'});
      this.loggedInUserData = [];
    })
  }
}
