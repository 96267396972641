import { Component, OnInit, Input } from '@angular/core';
import { ProductsService } from 'src/app/providers/products/products.service';
declare var UIkit: any;

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() productData: any;
  productsCategoriesData: any[];

  constructor(private productsProvider: ProductsService) {
    this.productsProvider.getAllProductsCategories().valueChanges().subscribe((data) => {
      this.productsCategoriesData = data;
    });
  }

  ngOnInit() {
  }

  getProductCategory(category) {
    if(this.productsCategoriesData && category) {
      return this.productsCategoriesData.find(function(item) {
        return item.id === category;
      })
    };
  }

}
