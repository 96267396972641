import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/providers/products/products.service';
declare var UIkit: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  productsOnSpecialData: any[];
  loading: boolean;
  productToPreviewData: any;

  constructor(private productsProvider: ProductsService) { }

  ngOnInit() {
    this.loadProducts();
  }

  loadProducts() {
    this.loading = true;
    this.productsProvider.getProductOnSpecial().valueChanges().subscribe((data) => {
      this.productsOnSpecialData = data;
      this.loading = false;
    })
  }

  openPreview(item) {
    this.productToPreviewData = item;
    UIkit.modal('#product-preview-modal').show();
  }

}
