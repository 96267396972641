import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { switchMap } from 'rxjs/operators';
declare var UIkit: any;

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  productsDB: AngularFirestoreCollection<any> = this.afs.collection('products');
  productsCategoriesDB: AngularFirestoreCollection<any> = this.afs.collection('categories');
  productsSizesDB: AngularFirestoreCollection<any> = this.afs.collection('ages');

  constructor(
    private afs: AngularFirestore,
  ) { }

  getAllProducts() {
    return this.productsDB;
  }

  getAllProductsCategories() {
    return this.productsCategoriesDB;
  }

  getProductByID(id) {
      let productDetails = this.afs.collection('products', ref => ref.where('id', '==', id));
      return productDetails;
  }

  getProductOnSpecial() {
      let productDetails = this.afs.collection('products', ref => ref.where('isOnSpecial', '==', true));
      return productDetails;
  }

  getAllProductsSizes() {
    return this.productsSizesDB;
  }

  getFilteredProducts(gender, productType) {
    this.afs.collection('products', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (productType) { query = query.where('productType', '==', productType) };
      if (gender) { query = query.where('gender', '==', gender) };
      return query;
    })
  }
}
