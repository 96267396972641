import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { switchMap } from 'rxjs/operators';
declare var UIkit: any;

@Injectable({
  providedIn: 'root'
})
export class CartProviderService {

  cartDB: AngularFirestoreCollection<any> = this.afs.collection('cart');

  constructor(private afs: AngularFirestore) {}

  getCart() {
    return this.cartDB;
  }

  updateCart(data) {
    return this.cartDB.doc(data.id).update(data).then(response => {
      UIkit.notification({message: 'Cart updated successfully', pos: 'bottom-center', status: 'success'});
    });
  }

  removeItem(data) {
    return this.cartDB.doc(data.id).delete().then(a => {
      UIkit.notification({message: 'Product removed successfully', pos: 'bottom-center', status: 'success'});
    });
  }
}
