import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
declare var UIkit: any;


@Injectable()
export class AuthProvidersService {
  
  usersDB: AngularFirestoreCollection<any> = this.afs.collection('users');

  constructor(public afAuth: AngularFireAuth, public afd: AngularFireDatabase, private afs: AngularFirestore) {}

  loginUser(newEmail: string, newPassword: string): Promise<any> {
    return this.afAuth.auth.signInWithEmailAndPassword(newEmail, newPassword);
  }

  getLoggedInUser(id) {
    let user = this.afs.collection('users', ref => ref.where('uid', '==', id));
    return user;
  }

  getAllUsers() {
    return this.usersDB;
  }

  getUserDetails(id) {
    let userData = this.afs.collection('users', ref => ref.where('id', '==', id));
    return userData;
  }

  resetPassword(email: string): Promise<any> {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  logoutUser(): Promise<any> {
    return this.afAuth.auth.signOut();
  }

  signupUser(userData): Promise<any> {
    return this.afAuth.auth.createUserAndRetrieveDataWithEmailAndPassword(userData.email, userData.password).then((data) =>{
      this.afAuth.auth.currentUser.sendEmailVerification().then(function() {
        UIkit.notification({ message: 'verification email has been sent to ' + userData.email, pos: 'bottom-center' });
       }, function(error) { console.log('An error happened'); });

      this.afAuth.auth.currentUser.updateProfile({ displayName: userData.fullName, photoURL:"null" }).then(() => {
        console.log(userData);
        this.usersDB.add({
          name: userData.fullName,
          phoneNumber: userData.phoneNumber,
          uid: data.user.uid,
          email: data.user.email,
          userType: 'CUSTOMER' }).then(res => {
          res.update({ id: res.id }).then(response => {
          });
        })
      })
    });
  }

  currentUser(): any {
    return this.afAuth.authState
  }

}