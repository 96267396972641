import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-main-top-navbar',
  templateUrl: './main-top-navbar.component.html',
  styleUrls: ['./main-top-navbar.component.scss']
})
export class MainTopNavbarComponent implements OnInit {
  @Input() userData: any;
  @Output() onLogout = new EventEmitter();
  cartData = JSON.parse(localStorage.getItem('smoochie-cart'));
  constructor() { }

  ngOnInit() {
  }

  logout() {
    this.onLogout.emit();
  }

  removeItem(id) {
    for( var i = 0; i < this.cartData.length; i++){ 
      if ( this.cartData[i].id === id) {
        this.cartData.splice(i, 1);
      }
    }
    localStorage.setItem('smoochie-cart', JSON.stringify(this.cartData));
  }

  getCartTotal = () => this.cartData.reduce((prev, cur) => {
      return prev + (cur.unitPrice * cur.quantity);
  }, 0);
}
