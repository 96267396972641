import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from 'angularfire2/firestore';
declare var UIkit: any;

@Injectable({
  providedIn: 'root'
})
export class AddressesService {

  addressesDB: AngularFirestoreCollection<any> = this.afs.collection('addresses');

  constructor(
    private afs: AngularFirestore
  ) { }


  addAddress(data) {
    return this.addressesDB.add(data).then(res => {
      res.update({ id: res.id }).then(response => {
        UIkit.notification({message: 'Address added successfully', pos: 'bottom-center', status: 'success'});
      });
    })
  }

  updateAddress(data) {
    return this.addressesDB.doc(data.id).update(data).then(response => {
      UIkit.notification({message: 'Address updated successfully', pos: 'bottom-center', status: 'success'});
    });
  }

  getCustomerAddresses(id) {
    let customerAddresses = this.afs.collection('addresses', ref => ref.where('customerID', '==', id));
    return customerAddresses;
  }

  deleteAddress(data) {
    return this.addressesDB.doc(data.id).delete().then(a => {
      UIkit.notification({message: 'Address deleted successfully', pos: 'bottom-center', status: 'success'});
    });
  }
}
