import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFirestore } from 'angularfire2/firestore';
import * as firebase from 'firebase/app';
import { Ng5SliderModule } from 'ng5-slider';
import { AngularRaveModule } from 'angular-rave';

import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './pages/home/home.component';
import { BrowseComponent } from './pages/browse/browse.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MainTopNavbarComponent } from './components/main-top-navbar/main-top-navbar.component';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';
import { ProductCardComponent } from './components/reusable/product-card/product-card.component';
import { ProductPreviewModalComponent } from './components/modals/product-preview-modal/product-preview-modal.component';
import { LoginModalComponent } from './components/modals/login-modal/login-modal.component';
import { AuthProvidersService } from './providers/auth/auth-provider.service';
import { CartProviderService } from './providers/cart/cart-provider.service';
import { ProductsService } from './providers/products/products.service';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { ProductImageComponent } from './components/reusable/product-image/product-image.component';
import { LoaderComponent } from './components/reusable/loader/loader.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { RegisterModalComponent } from './components/modals/register-modal/register-modal.component';
import { AddressesService } from './providers/users/addresses/addresses.service';
import { FooterComponent } from './components/footer/footer.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'browse', component: BrowseComponent },
  { path: 'shopping-cart', component: ShoppingCartComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'product-details/:id', component: ProductDetailsComponent,
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BrowseComponent,
    NavbarComponent,
    MainTopNavbarComponent,
    ProductDetailsComponent,
    ProductCardComponent,
    ProductPreviewModalComponent,
    LoginModalComponent,
    ShoppingCartComponent,
    ProductImageComponent,
    LoaderComponent,
    CheckoutComponent,
    RegisterModalComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      appRoutes,{ enableTracing: false }
    ),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng5SliderModule,
    AngularRaveModule
  ],
  providers: [
    AngularFirestore,
    AuthProvidersService,
    CartProviderService,
    ProductsService,
    AddressesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
