import { Component, OnInit } from '@angular/core';
import { AuthProvidersService } from 'src/app/providers/auth/auth-provider.service';
import { ProvinceData } from 'src/app/models/provinces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressesService } from 'src/app/providers/users/addresses/addresses.service';
import { RaveOptions } from 'angular-rave';



declare var UIkit: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  loggedInUserData: any;
  addressData: any;
  currentUserData: any;
  addressToEdit: any;
  currentUserAddressesData: any;
  selectedDeliveryAddressData: any;
  showContent: any = null;
  form: FormGroup;
  loading: boolean;
  toggleAddAddress: boolean = false;
  public provinceData: any[] = ProvinceData;
  selectedAddress: any = 0;
  isSuccessPayment: boolean = false;
  cartData = JSON.parse(localStorage.getItem('smoochie-cart'));
  stepIndex: number = 0;

  constructor(
    private authData: AuthProvidersService,
    private formBuilder: FormBuilder,
    private addressProvider: AddressesService,
    ) {
  }

  ngOnInit() {
    this.loadLoggedInUser();
    this.initializeForm([]);
    this.stepIndex = 0;
    this.isSuccessPayment = false;
  }

  initializeForm(data) {
    this.form = this.formBuilder.group({
      name: [data ? data.name : null, Validators.required],
      phone: [data ? data.phone : null, Validators.required],
      streetAddress: [data ? data.streetAddress : null, Validators.required],
      building: [data ? data.building : null],
      surburb: [data ? data.surburb : null, Validators.required],
      city: [data ? data.city : null, Validators.required],
      province: [data ? data.province : null, Validators.required],
      postalCode: [data ? data.postalCode : null, Validators.required]
    })
  }

  loadLoggedInUser() {
    this.authData.currentUser().subscribe((data) => {
      this.showContent = data;
      if(data) {
        this.showContent = data;
        if(this.showContent) {
          this.loadCurrentUser(this.showContent.uid);
        }
        if(!data) {
          UIkit.modal('#modal-login').show();
        }
      }
      else {
        UIkit.modal('#modal-login').show();
      }
    });
  }

  loadCurrentUser(uid) {
      this.authData.getLoggedInUser(uid).valueChanges().subscribe((data) => {
        this.currentUserData = data;
        if(this.currentUserData[0]) {
          this.loadCustomerAddresses(this.currentUserData[0].id);
        }
      })
  }

  loadCustomerAddresses(id) {
      this.addressProvider.getCustomerAddresses(id).valueChanges().subscribe((data) => {
        this.currentUserAddressesData = data;
        this.selectedDeliveryAddressData = this.currentUserAddressesData[this.selectedAddress];
        console.log(this.selectedDeliveryAddressData);
      });
  }

  getCartTotal = () => this.cartData.reduce((prev, cur) => {
      return prev + (cur.unitPrice * cur.quantity);
  }, 0);

  onAddressAdd() {
    this.addressData = this.form.value;
    if(this.addressToEdit.id && this.toggleAddAddress) {
      debugger
      this.addressData.id = this.addressToEdit.id;
      this.updateAddress(this.addressData);
    }
    else {
      this.addNewAddress(this.addressData);
    }
  }

  addNewAddress(data) {
    this.loading = true;
    this.addressData.customerID = this.currentUserData[0].id;
    this.addressProvider.addAddress(data).then(() => {
      this.loading = false;
      this.toggleAddAddress = false;
      this.form.reset();
    });
  }

  updateAddress(data) {
    this.loading = true;
    this.addressProvider.updateAddress(data).then(() => {
      this.loading = false;
      this.toggleAddAddress = false;
      this.form.reset();
    });
  }

  toggleEditAddress(address) {
    this.toggleAddAddress = true;
    this.addressToEdit = address;
    this.initializeForm(address);
  }

  cancelAddEditAddress() {
    this.toggleAddAddress = false;
    this.addressToEdit = [];
    this.form.reset();
  }

  public openDeleteAddress(data) {
    UIkit.modal.confirm('Are you sure you want to delete this address').then(() => {
      this.addressProvider.deleteAddress(data);
    },() => { console.log('Rejected.') });
  }

  onToggleAddAddress() {
    this.toggleAddAddress = !this.toggleAddAddress;
    if(this.toggleAddAddress) {
      this.addressToEdit = [];
    }
  }

  selectAddress(indx) {
    this.selectedAddress = indx;
    this.selectedDeliveryAddressData = this.currentUserAddressesData[this.selectedAddress];
  }

  goToPayment() {
    UIkit.switcher('#checkout-nav').show(1);
    this.stepIndex = 1;
  }


  paymentOptions: RaveOptions = {
    PBFPubKey: 'FLWPUBK-37e478bd1d4123a36c5dbbb7947cc46f-X',
    customer_email: this.currentUserData ? this.currentUserData[0].email : '',
    customer_firstname: this.currentUserData ? this.currentUserData[0].name : '',
    customer_lastname: '',
    custom_logo: "http://smoochiechild.com/store/assets/images/logos/logo.jpg",
    custom_title: "Smoochie Child",
    custom_description: 'Payment for goods',
    amount: this.getCartTotal(),
    customer_phone: this.currentUserData ? this.currentUserData[0].phoneNumber : '',
    txref: `${Math.random() * 1000000}`,
  }

  onPaymentSuccess(e) {
    this.isSuccessPayment = true;
    if(e.respcode === '00' || e.respcode === '0') {
      UIkit.switcher('#checkout-nav').show(2);
    }
    else {
      this.isSuccessPayment = false;
    }
    console.log('payment', e);
  }

  onPaymentFailure() {
    console.log('payment unsuccessfull');
  }

  paymentInit() {
    console.log('payment started');
    this.paymentOptions.customer_email = this.currentUserData[0].email;
    this.paymentOptions.customer_firstname = this.currentUserData[0].name;
    this.paymentOptions.customer_phone = this.currentUserData[0].phoneNumber;
  }
}
