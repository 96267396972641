import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthProvidersService } from 'src/app/providers/auth/auth-provider.service';
declare var UIkit: any;

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  email: string;
  password: string;
  loading: boolean = false;
  form: FormGroup;
  isResetPassword: boolean = false;
  @Input() hideLoginCloseButton: any;
  
  constructor(
    public authData: AuthProvidersService,
    private formBuilder: FormBuilder
    ) {

      this.form = this.formBuilder.group({
        email: ['', Validators.required],
        password: ['', Validators.required]
      })

      console.log('hideLoginCloseButton', this.hideLoginCloseButton);
  }

  ngOnInit() {
  }

  ngOnChange(){
  }

  toggleResetPassword() {
    this.isResetPassword = !this.isResetPassword;
  }

  loginUser() {
    this.loading = true;
    this.authData.loginUser(this.form.value.email, this.form.value.password)
    .then( authData => {
      UIkit.modal('#modal-login').hide();
      this.loading = false;
      this.form = this.formBuilder.group({email: [''],password: ['']});
    }, (error) => {
      UIkit.notification({message: error.message, pos: 'bottom-center', status: 'danger'});
      this.loading = false;
    });
  }
}

