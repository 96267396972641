import { Component, OnInit, HostListener } from '@angular/core';
import { ProductsService } from 'src/app/providers/products/products.service';
import { Location } from '@angular/common';
import { Options, LabelType } from 'ng5-slider';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
declare var UIkit: any;

export interface productModel {
  code: string
  colour: string
  description: string
  gender: string
  id: string
  image: string
  name: string
  productType: string
  quantity: number
  size: any
}

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss']
})
export class BrowseComponent implements OnInit {
  // productsData$: any[];
  genderFilter: string = '';
  loading: boolean;
  productsCategoriesData: any[];
  specialsActivated: boolean = false;
  screenHeight:any;
  screenWidth:any;
  genderParam: string;
  isOnSpecialParam: boolean;

  productsData$: Observable<Object>;
  filterByGender$ = new Subject();
  filterByProductType$ = new Subject();
  filterByMinPrice$ = new Subject();
  filterByMaxPrice$ = new Subject();
  filterBySpecials$ = new Subject();

  minValue: number = 0;
  maxValue: number = 1000;
  options: Options = {
    floor: 10,
    ceil: 1000,
    step: 10,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min:</b> R' + value;
        case LabelType.High:
          return '<b>Max:</b> R' + value;
        default:
          return 'R' + value;
      }
    }
  };
  productToPreviewData: any;

  constructor(
    private productsProvider: ProductsService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore
    ){
    this.productsProvider.getAllProductsCategories().valueChanges().subscribe((data) => {
      this.productsCategoriesData = data;
    });

    this.getScreenSize();

    this.genderParam = this.route.snapshot.params['gender'];
    this.genderFilter = this.genderParam ? this.genderParam : '';
    this.isOnSpecialParam = Boolean(this.route.snapshot.params['isOnSpecial']);

    this.filterByGender$ = new BehaviorSubject(null);
    this.filterByProductType$ = new BehaviorSubject(null);
    this.filterByMinPrice$ = new BehaviorSubject(null);
    this.filterByMaxPrice$ = new BehaviorSubject(null);
    this.filterBySpecials$ = new BehaviorSubject(null);

    this.productsData$ = combineLatest(
      this.filterByGender$,
      this.filterByProductType$,
      this.filterByMinPrice$,
      this.filterByMaxPrice$,
      this.filterBySpecials$
    ).pipe(switchMap(([gender, productType, minPrice, maxPrice]) => 
      this.afs.collection('products', ref => {
          let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
          if (gender) { query = query.where('gender', '==', gender) };
          if (productType) { query = query.where('productType', '==', productType) };
          if (minPrice) { query = query.where(!this.specialsActivated ? "unitPrice" : "specialPrice", ">=", minPrice) };
          if (maxPrice) { query = query.where(!this.specialsActivated ? "unitPrice" : "specialPrice", "<=", maxPrice) };
          if (this.specialsActivated) { query = query.where("isOnSpecial", "==", true) };
          return query;
      }).valueChanges()));
  }

  ngOnInit() {
    this.onGenderChange(this.genderParam);
    this.onSpecialsFilter(this.isOnSpecialParam)
  }

  getProductCategory(category) {
    if(this.productsCategoriesData && category) {
      return this.productsCategoriesData.find(function(item) {
        return item.id === category;
      })
    };
  }

  @HostListener('window:resize', ['$event'])

  getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
  }

  onPriceChange(min, max) {
    this.minValue = min;
    this.maxValue = max;
    this.filterByMinPrice$.next(min);
    this.filterByMaxPrice$.next(max);
  }

  onGenderChange(gender: string) {
    this.filterByGender$.next(gender);
  }

  onProductTypeChange(productType: string) {
    this.filterByProductType$.next(productType); 
  }

  onSpecialsFilter(val) {
    this.specialsActivated = val;
    this.filterBySpecials$.next(this.specialsActivated); 
  }

  clearFilters() {
    this.onProductTypeChange(null);
    this.onSpecialsFilter(null);
    this.onPriceChange(this.minValue, this.maxValue);
  }

  clearGender() {
    this.onGenderChange(null);
    this.genderFilter = '';
    this.resetPath();
  }

  resetPath() {
    this.router.navigate(['browse']);
  }

  openPreview(item) {
    this.productToPreviewData = item;
    UIkit.modal('#product-preview-modal').show();
  }
}
