import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/providers/products/products.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {
  cartData: any;
  productsData: any;
  loading: boolean;

  constructor(private productsService: ProductsService) {
  }

  ngOnInit() {
    this.cartData = JSON.parse(localStorage.getItem('smoochie-cart'));
    this.loadProducts();
    this.getCartTotal();
  }

  ngOnChange() {
    this.cartData = JSON.parse(localStorage.getItem('smoochie-cart'));
  }

  loadProducts() {
    this.loading = true;
    this.productsService.getAllProducts().valueChanges().subscribe((data) => {
      this.productsData = data;
      this.loading = false;
    });
  }

  getProductDetails(id) {
    if(this.productsData) {
      let product = this.productsData.find(product => product.id === id);
      return product;
    }
  }

  getCartTotal = () => this.cartData.reduce((prev, cur) => {
      return prev + (cur.unitPrice * cur.quantity);
  }, 0);

  removeItem(id) {
    for( var i = 0; i < this.cartData.length; i++){ 
      if ( this.cartData[i].id === id) {
        this.cartData.splice(i, 1);
      }
    }
    localStorage.setItem('smoochie-cart', JSON.stringify(this.cartData));
  }
}
