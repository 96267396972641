import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthProvidersService } from 'src/app/providers/auth/auth-provider.service';
declare var UIkit: any;

@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss']
})
export class RegisterModalComponent implements OnInit {

  email: string;
  password: string;
  loading: boolean = false;
  form: FormGroup;

  constructor(
    public authData: AuthProvidersService,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      fullName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    })
  }

  ngOnInit() {
  }

  checkIfPasswordMatches = () => {
    return this.form.value.password == this.form.value.confirmPassword;
  };

  registerUser() {
    this.loading = true;
    this.authData.signupUser(this.form.value)
    .then( authData => {
      UIkit.modal('#modal-register').hide();
      this.loading = false;
      this.form = this.formBuilder.group({fullName: [''], phoneNumber: [''], email: [''],password: [''], confirmPassword: ['']});
    }, (error) => {
      UIkit.notification({message: error.message, pos: 'bottom-center', status: 'danger'});
      this.loading = false;
    });
  }

}
