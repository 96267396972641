import { Component, OnInit, Input } from '@angular/core';
import { ProductsService } from 'src/app/providers/products/products.service';
declare var UIkit: any;

@Component({
  selector: 'app-product-preview-modal',
  templateUrl: './product-preview-modal.component.html',
  styleUrls: ['./product-preview-modal.component.scss']
})
export class ProductPreviewModalComponent implements OnInit {
  productData: any = [];
  productQty: number = 1;
  cart = JSON.parse(localStorage.getItem('smoochie-cart'));
  @Input() data;
  productsCategoriesData: any[];
  constructor(private productsProvider: ProductsService) {
    this.productsProvider.getAllProductsCategories().valueChanges().subscribe((data) => {
      this.productsCategoriesData = data;
    });
  }

  ngOnInit() {
    this.productData = this.data;
    this.cart = JSON.parse(localStorage.getItem('smoochie-cart'));
  }

  ngOnChanges() {
    this.productData = this.data;
    this.productQty = 1;
    this.cart = JSON.parse(localStorage.getItem('smoochie-cart'));
  }
  
  decreaseQty() {
    this.productQty --;
    this.productQty = this.productQty <= 1 ? 1 : this.productQty;
  }

  increaseQty() {
    this.productQty ++;
    this.productQty = this.productQty >= this.productData.quantity ? this.productData.quantity : this.productQty;
  }

  addToCard(item) {
    let temp = this.cart;
    let cartData = {
      id: item.id,
      quantity: this.productQty,
      name: item.name,
      unitPrice: item.unitPrice,
      code: item.code
    };
    if(this.cart) {
      temp.push(cartData);
      localStorage.setItem('smoochie-cart', JSON.stringify(temp));
      UIkit.notification({message: 'Item added to cart', pos: 'bottom-center', status: 'success'});
    }
    else {
      localStorage.setItem('smoochie-cart', JSON.stringify([cartData]));
      UIkit.notification({message: 'Item added to cart', pos: 'bottom-center', status: 'success'});
    }
  }

  getProductCategory(category) {
    if(this.productsCategoriesData && category) {
      return this.productsCategoriesData.find(function(item) {
        return item.id === category;
      })
    };
  }
}